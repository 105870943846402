import React from 'react';
import { graphql, useStaticQuery, Link } from 'gatsby';
import Img from 'gatsby-image';
import { Container, Grid } from '@material-ui/core';
import FeatureSectionLight from '../components/featureSectionLight';
import FeatureCardsSection from '../components/featureCardsSection';
import PageSpeedIcon from '../../assets/svg/szybkie-strony.svg';
import FunctionalityIcon from '../../assets/svg/funkcjonalnosc-strony-internetowej.svg';
import ComplexIcon from '../../assets/svg/kompleksowa-usluga.svg';

import OkreslenieWymagan from '../../assets/svg/okreslenie-wymagan.svg';
import ProjektowanieLayoutu from '../../assets/svg/projektowanie-layoutu.svg';
import PrototypowanieImplementacja from '../../assets/svg/prototypowanie-implementacja.svg';
import Publikacja from '../../assets/svg/publikacja-strony-internetowej.svg';
import Wsparcie from '../../assets/svg/wsparcie-i-doradztwo.svg';
import SubLayout from '../layouts/subLayout';
import WordPressIcon from '../../assets/svg/wordpress-plain.svg';
import ReactIcon from '../../assets/svg/react-original.svg';
import GatsbyIcon from '../../assets/svg/gatsby-plain.svg';
import AllServices from '../components/allServices';
import SEO from '../components/seo';

const TestPage = () => {
  const data = useStaticQuery(graphql`
        query WebImages {
            responsywne: file(
                relativePath: {
                    eq: "responsywne-strony-internetowe-iqcode.jpg"
                }
            ) {
                id
                childImageSharp {
                    fluid(quality: 95, traceSVG: { threshold: 22 }) {
                        ...GatsbyImageSharpFluid
                    }
                }
            }
            nowoczesne: file(
                relativePath: { eq: "nowoczesny-design-strony-internetowe.jpg" }
            ) {
                id
                childImageSharp {
                    fluid(quality: 95, traceSVG: { threshold: 50 }) {
                        ...GatsbyImageSharpFluid
                    }
                }
            }
            kompleksowa: file(relativePath: { eq: "kompleksowa-usluga.png" }) {
                id
                childImageSharp {
                    fluid(quality: 95, traceSVG: { threshold: 22 }) {
                        ...GatsbyImageSharpFluid
                    }
                }
            }
        }
    `);

  return (
    <SubLayout
      title={(
        <>
          Strony i Serwisy
          {' '}
          <span>Internetowe</span>
        </>
              )}
      subtitle="Nowoczesne i szybkie"
      img="/img/nextcloud-bg.jpg"
    >
      <SEO title="Strony i serwisy internetowe - iqcode Software House" description="Tworzymy responsywne i szybkie strony oraz serwisy internetowe z wykorzystaniem nowoczesnych technologii takich jak React.js oraz Gatsby.js" />
      <FeatureSectionLight
        mb0
        invert
        title={(
          <>
            Przyjazne dla Urządzeń
            {' '}
            <span>Mobilnych</span>
          </>
                  )}
        img={(
          <Img
            alt="responsywne strony internetowe"
            fluid={data.responsywne.childImageSharp.fluid}
          />
                )}
        desc={(
          <>
            <p>
              Według
              {' '}
              <a
                href="http://ranking.gemius.com/pl/ranking/platforms/"
                rel="external nofollow"
              >
                badań Gemius
              </a>
              {' '}
              prawie połowa ruchu w internecie generowana jest
              przez
              {' '}
              <strong>urządzenia mobilne</strong>
              . Z tego
              powodu niezwykle istotne jest zapewnienie poprawnego
              wyświetlania strony na niewielkich ekranach
              smartfonów i tabletów, oraz dostosowanie nawigacji
              po stronie internetowej do urządzeń dotykowych.
            </p>
            <p>
              Tworzone przez nas strony są
              {' '}
              <strong>responsywne</strong>
              {' '}
              &#8211; wzajemne
              ułożenie elementów, oraz ich rozmiary zmieniają się
              w zależności od rozmiaru ekranu na którym strona
              jest przeglądana. Elementy interfejsu użytkownika
              naszych stron internetowych są przystosowane do
              {' '}
              <strong>sterowania dotykiem</strong>
              . Te zabiegi
              gwarantują wygodne korzystanie ze strony na każdym
              urządzeniu.
            </p>
          </>
                  )}
      />
      <FeatureSectionLight
        title={(
          <>
            Posiadające nowoczesny
            {' '}
            <span>Design</span>
          </>
                  )}
        img={(
          <Img
            fluid={data.nowoczesne.childImageSharp.fluid}
            alt="Nowoczesne strony internetowe"
          />
                )}
        desc={(
          <>
            <p>
              Dla przedsiębiorstwa, strona internetowa pełni
              niezwykle istotną
              {' '}
              <strong>rolę reprezentacyjną</strong>
              . Z tego powodu
              bardzo ważne jest
              {' '}
              <strong>user experience</strong>
              ,
              które ona oferuje już od pierwszych sekund
              przeglądania. Mając to na uwadze, nadajemy tworzonym
              przez nas stronom internetowym profesjonalny i
              przejrzysty wygląd, nierzadko czerpiąc inspirację z
              nowoczesnych stylów, takich jak
              {' '}
              <a
                href="https://material.io/"
                rel="external nofollow"
              >
                material design
              </a>
              .
            </p>
            <p>
              Wykazujemy się szczególną dbałością o detale, które
              nadają stronie internetowej profesjonalny wygląd,
              wzbudzający zaufanie wśród klientów.
            </p>
          </>
                  )}
        link="Porozmawiajmy"
        to="/kontakt/"
      />

      <div className="promo overlay-holder z-depth-3">
        <div className="diagonal-overlay-light" />
        <div className="rel">
          <h3>
            Chcesz wycenić
            {' '}
            <span>stronę internetową</span>
            ?
          </h3>
          <a className="btn" href="/kontakt/">
            Napisz do nas!
          </a>
        </div>
      </div>

      <FeatureCardsSection
        title={(
          <>
            Dlaczego
            {' '}
            <span>warto</span>
          </>
                  )}
        subtitle="Realizować stronę internetową z iqcode"
        cards={[
          {
            title: (
              <>
                Szybkie
                <br />
                Działanie
              </>
            ),
            icon: <PageSpeedIcon />,
            desc:
                            'Nowoczesne rozwiązania takie jak Gatsby.js zapewniają znakomitą szybkość działania strony.',
            link: 'Nasze technologie',
            to: '/technologie/',
          },
          {
            title: (
              <>
                Kompleksowa
                <br />
                Usługa
              </>
            ),
            icon: <ComplexIcon />,
            desc:
                            'Dzięki szerokiemu wachlarzowi stosowanych przez nas technologii, możemy rozwinąć twój projekt programistyczny.',
            link: 'Wszystkie usługi iqcode',
            to: '/',
          },
          {
            title: (
              <>
                Bogata
                <br />
                Funkcjonalność
              </>
            ),
            icon: <FunctionalityIcon />,
            desc:
                            'Nasze autorskie aplikacje serwerowe umożliwiają implementację rozbudowanej funkcjonalności serwisów internetowych.',
            link: 'Aplikacje serwerowe',
            to: '/aplikacje-serwerowe/',
          },
        ]}
      />

      <div className="technologies bg-dark outline-fix z-depth-3">
        <Container>
          <h3>
            <span>Technologie</span>
            {' '}
            których używamy
          </h3>

          <Grid container>
            <Grid className="tech-cont" item xs={12} sm={12} md={4}>
              <ReactIcon className="tech-icon" />
              <p>React.js</p>
            </Grid>
            <Grid className="tech-cont" item xs={12} sm={12} md={4}>
              <GatsbyIcon className="tech-icon" />
              <p>Gatsby.js</p>
            </Grid>
            <Grid className="tech-cont" item xs={12} sm={12} md={4}>
              <WordPressIcon className="tech-icon" />
              <p>WordPress</p>
            </Grid>
          </Grid>
          <Link className="btn btn-alt" to="/technologie/">
            Więcej informacji
          </Link>
        </Container>
      </div>

      <div className="timeline">
        <Container>
          <h2>
            Etapy realizacji
            {' '}
            <span>Strony Internetowej</span>
          </h2>
          <h5>Jak wygląda nasza współpraca</h5>
          <div className="timeline">
            <div className="timeline-event">
              <div className="timeline-inner">
                <h3>Określenie wymagań projektu</h3>
                <p>
                  Nasza współpraca zaczyna się od zbadania
                  potrzeb klienta. Przeprowadzamy analizy
                  konkurencji i określamy grupę odbiorców.
                </p>
                <p>
                  Na tym etapie podejmujemy decyzje dotyczące
                  stosowanych technologii. Dobieramy system
                  zarządzania treścią (CMS).
                </p>
              </div>
              <div className="timeline-num">
                <span>01</span>
              </div>
              <OkreslenieWymagan className="timeline-icon" />
            </div>
            <div className="timeline-event">
              <div className="timeline-inner-right">
                <h3>Projektowanie layoutu</h3>
                <p>
                  Tworzymy projekty graficzne docelowego
                  layoutu strony. Wspólnie z klientem
                  analizujemy silne i słabe strony
                  poszczególnych wizualizacji. Ten etap kończy
                  się wyborem stylu strony.
                </p>
              </div>
              <div className="timeline-num-right">
                <span>02</span>
              </div>
              <ProjektowanieLayoutu className="timeline-icon" />
            </div>
            <div className="timeline-event">
              <div className="timeline-inner">
                <h3>Prototypowanie</h3>
                <p>
                  Rozpoczynamy implementację strony
                  internetowej. Na bieżąco przesyłamy
                  klientowi kolejne wersje prototypowe strony,
                  które w sposób iteratywny udoskonalamy.
                </p>
                <p>
                  Ten etap kończy się zbadaniem poprawności
                  implementacji strony internetowej. Upewniamy
                  się że strona działa poprawnie niezależnie
                  od rodzaju urządzenia odbiorcy oraz używanej
                  przeglądarki.
                </p>
              </div>
              <div className="timeline-num">
                <span>03</span>
              </div>
              <PrototypowanieImplementacja className="timeline-icon" />
            </div>
            <div className="timeline-event">
              <div className="timeline-inner-right">
                <h3>Publikacja strony internetowej</h3>
                <p>
                  Gdy wszystkie prace zostaną zakończone,
                  przystępujemy do publikacji. Uruchamiamy
                  usługi dodatkowe i przeprowadzamy szkolenie
                  z zakresu obsługi wykorzystanego systemu
                  zarządzania treścią.
                </p>
              </div>
              <div className="timeline-num-right">
                <span>04</span>
              </div>
              <Publikacja className="timeline-icon" />
            </div>
            <div className="timeline-event">
              <div className="timeline-inner">
                <h3>Rozwój i wsparcie techniczne</h3>
                <p>
                  Pozostajemy w kontakcie z naszymi klientami
                  i świadczymy usługi wsparcie technicznego.
                  Przedstawiamy cykliczne analizy statystyk
                  strony oraz sugerujemy kierunki rozwoju.
                </p>
              </div>
              <div className="timeline-num">
                <span>05</span>
              </div>
              <Wsparcie className="timeline-icon" />
            </div>
          </div>
        </Container>
      </div>

      <div className="seo-icons overlay-holder bg-dark">
        <div className="diagonal-overlay-light" />
        <div className="row rel">
          <h2
            style={{ marginBottom: '30px', marginTop: '20px' }}
            className="promobox"
          >
            Pakiet korzyści
            {' '}
            <span className="p">w standardzie</span>
          </h2>
          <div className="col s12 m6 l6 xl3">
            <div className="seo-card">
              <div className="seo-icon">
                <img
                  src="/img/optymalizacja-seo-strony-internetowej.svg"
                  alt="analiza konkurencji"
                />
              </div>
              <h3 className="seo-icons-desc">
                Optymalizacja SEO
              </h3>
              <p>
                Kod tworzonych przez nas stron internetowych
                jest optymalizowany dla
                {' '}
                <strong>korzystnego indeksowania</strong>
                {' '}
                przez
                {' '}
                <strong>wyszukiwarki internetowe</strong>
                .
              </p>
            </div>
          </div>
          <div className="col s12 m6 l6 xl3">
            <div className="seo-card">
              <div className="seo-icon">
                <img
                  src="/img/firmowy-email.svg"
                  alt="znajdowanie słów kluczowych"
                />
              </div>
              <h3 className="seo-icons-desc">Firmowy e-mail</h3>
              <p>
                W standardzie zapewniamy obsługę profesjonaline
                skonfigurowanych kont e-mail w domenie strony
                internetowej firmy.
              </p>
            </div>
          </div>
          <div className="col s12 m6 l6 xl3">
            <div className="seo-card">
              <div className="seo-icon">
                <img
                  src="/img/wydajny-hosting.svg"
                  alt="optymalizacja kodu strony internetowej"
                />
              </div>
              <h3 className="seo-icons-desc">Wydajny hosting</h3>
              <p>
                Zapewniamy utrzymanie strony internetowej na
                wydajnych serwerach z zastosowaniem usługi CDN
                dla skrócenia czasu ładowania strony.
              </p>
            </div>
          </div>
          <div className="col s12 m6 l6 xl3">
            <div className="seo-card">
              <div className="seo-icon">
                <img
                  src="/img/gwarancja-dostepnosci-serwera.svg"
                  alt="doradztwo dla rozwoju"
                />
              </div>
              <h3 className="seo-icons-desc">
                Gwarantowana dostępność
              </h3>
              <p>
                Automatycznie monitorujemy dostępność
                uruchomionych przez stron i serwisów. Zawieramy
                rygorystyczne
                {' '}
                <strong>umowy SLA</strong>
                .
              </p>
            </div>
          </div>
        </div>
      </div>

      <FeatureSectionLight
        mb0
        title={(
          <>
            <span>Kompleksowa </span>
            Usługa
          </>
                  )}
        img={(
          <div style={{ padding: '10%' }}>
            <Img
              alt="nowoczesne aplikacje mobilne"
              fluid={data.kompleksowa.childImageSharp.fluid}
              imgStyle={{ width: 'auto' }}
            />
          </div>
                  )}
        desc={(
          <>
            <p>
              Przeprowadzamy integracje stron internetowych z
              {' '}
              <Link to="/systemy-crm-erp/">systemami CRM/ERP</Link>
              , oprogramowaniem e-commerce oraz
              aplikacjami branżowymi. Posługujemy się obszernym
              zbiorem
              {' '}
              <Link to="/technologie/">technologii programistycznych</Link>
              , dzięki czemu tworzone przez nas
              produkty cyfrowe mogą być rozbudowywane wraz z
              rozwojem firm naszych klientów.
            </p>
            <p>
              Tworzenie stron oraz serwisów internetowych to nie
              jedyny obszar specjalizacji software house'u iqcode. Oferujemy
              kompleksową realizację projektów informatycznych
              począwszy od
              {' '}
              <Link to="/consulting-it/">projektowania oprogramowania</Link>
              {' '}
              i
              inżynierii wymagań, poprzez prace programistyczne i
              doradztwo projektowe związane z debiutem rynkowym
              aplikacji, a zakończywszy na wsparciu technicznym,
              analityce i ciągłym rozwoju produktu cyfrowego.
            </p>
          </>
                  )}
        link="Porozmawiajmy"
        to="/kontakt/"
      />
      <AllServices />
    </SubLayout>
  );
};

export default TestPage;
